import * as React from 'react';

import { useDarkMode } from 'usehooks-ts';

declare global {
  interface Window {
    __GIT_COMMIT_SHA__: string;
  }
}

type ThemeContextType = { isDarkMode: boolean; toggleDarkMode: () => void };

export const ThemeContext = React.createContext<ThemeContextType>({
  isDarkMode: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleDarkMode: () => {},
});

export const AppWrapper = ({
  element,
}: {
  element: React.ReactNode;
}): React.JSX.Element => {
  const { isDarkMode, toggle: toggleDarkMode } = useDarkMode();

  React.useEffect(() => {
    if (typeof document !== `undefined` && typeof window !== `undefined`) {
      document.documentElement.classList.add('[&_*]:!transition-none');
      window.setTimeout(() => {
        document.documentElement.classList.remove('[&_*]:!transition-none');
      }, 0);

      if (isDarkMode) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    }
  }, [isDarkMode]);

  if (typeof window !== `undefined`) {
    window.__GIT_COMMIT_SHA__ = process.env.GATSBY_GIT_COMMIT_SHA || 'unknown';
  }

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      {element}
    </ThemeContext.Provider>
  );
};

export default AppWrapper;
